import styled from '@emotion/styled';
import React from 'react';
import { Content, getContentSearchId } from './apiService';
import CardBadge from './CardBadge';
import ContentRatings from './ContentRatings';
import CountryYear from './CountryYear';
import { Flex, Card, CardBody, Text, CardImage } from './styles';
import Ellipsis from './styles/Ellipsis';
import { durationToWords, getContentType, getEpisodeIdentifier, getGenreTitles } from './utils';
import link from './styles/Link';

const ContentCard = ({ content }: { content: Content }) => {
  const {
    search_query_id,
    search_position,
    poster_image,
    id,
    short_id,
    slug,
    title,
    year,
    duration,
    categories,
    type,
    flag,
    conditional_flag,
    page_title,
    sub_title,
    episode,
  } = content;

  const durationWords = duration && durationToWords(duration);
  const genres = getGenreTitles(categories, 2);
  const episodeIdentifier = getEpisodeIdentifier({ ...content });
  const contentType = getContentType(type);

  const href = `/contents/${short_id}/${slug}`;
  const image = `${poster_image?.path}?w=300`;
  const handleNavigate = () => {
    if (search_query_id && search_position) {
      getContentSearchId(id, search_position, search_query_id);
    }
  };
  return (
    <CardContainer stack={contentType === 'tv_show'} onClick={handleNavigate}>
      <Card orientation="vertical" as={link} href={href} title={page_title ?? title} block data-center-ref>
        <CardImage src={image} alt={title} loading="lazy" />
        <CardBadge flag={flag + ', ' + conditional_flag} />
        <Flex as={CardBody} direction="column" justify="flex-end">
          <Flex direction="column" align="flex-start" justify="flex-end">
            <Flex>
              <Text variant="body2" weight="medium" ml={1} mb={2}>
                {genres}
              </Text>
            </Flex>
            <Ellipsis>
              <CountryYear textOnly year={year} categories={categories} limit={2} />
            </Ellipsis>
            <Text variant="body2" pt={1} pb={2}>
              {durationWords}
            </Text>
            <ContentRatings {...content} split contained={false} />
          </Flex>
        </Flex>
      </Card>

      <Text as={Ellipsis} variant="body2" pt={1}>
        {episode?.toString() === '0' ? sub_title : (episodeIdentifier || title)}
      </Text>
    </CardContainer>
  );
};

// ------ //
// STYLES //
// ------ //

export const CardContainer = styled.div<{ stack?: boolean }>(({ theme, stack }) => ({
  marginTop: '.5rem',
  marginBottom: '.5rem',
  position: 'relative',
  [`${Card}`]: {
    backgroundImage: 'url(/static/images/placeholder/poster.svg)',
    '+ div': {
      lineHeight: 1,
    },
  },
  '&::before, &::after': {
    content: '""',
    display: stack ? 'block' : 'none',
    height: 1,
    backgroundColor: theme.palette.foreground[100][0],
    opacity: 0.6,
    position: 'absolute',
    right: '50%',
    transform: 'translateX(50%)',
  },
  '&::before': {
    width: '75%',
    top: '-.5rem',
  },
  '&::after': {
    width: '85%',
    top: '-.25rem',
  },
}));

export default ContentCard;
